import {createGlobalStyle} from 'styled-components';

export const lightMode = {
    body: 'linear-gradient(90deg, rgba(245,255,249,1) 0%, rgba(210,255,239,1) 100%)',
    nav: 'linear-gradient(90deg, rgba(245,255,249,1) 0%, rgba(210,255,239,1) 100%)',
    navMobile: 'linear-gradient(90deg, rgba(245,255,249,1) 0%, rgba(210,255,239,1) 100%)',
    moz: '-moz-linear-gradient(90deg, rgba(245,255,249,1) 0%, rgba(210,255,239,1) 100%)',
    webkit: '-webkit-linear-gradient(90deg, rgba(245,255,249,1) 0%, rgba(210,255,239,1) 100%)',
    cardColor: 'rgba(0,0,0,0.04)',
    fontColor: '#131537',
    accentColor: '#02a686',
    scrollThumbColor: '#02a686',
    scrollThumbHover: 'rgb(16, 15, 41)'
}

export const darkMode = {
    body: '#16183A',
    nav: '#16183A',
    navMobile: '#16183A',
    cardColor: '#13142A',
    cardBorderColor: 'transparent',
    fontColor: '#a8b8cf',
    accentColor: '#F9FF56',
    scrollThumbColor: '#a8b8cf',
    scrollThumbHover: '#F9FF56'
};

export const GlobalStyle = createGlobalStyle`  
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
    }
    body{
        background: ${(props) => props.theme.moz};
        background: ${(props) => props.theme.webkit};
        background: ${(props) => props.theme.body};
        color: ${(props) => props.theme.fontColor};
        line-height: 1.4;
        scroll-behavior: smooth;
        font-family: 'Lato', sans-serif;
        cursor: auto;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        font-family: 'Open Sans', sans-serif;    
    }
    
    h1{ font-size: 64px;}
    h2{ font-size: 32px;}
    h3{ font-size: 18px;}
    h4{ font-size: 16px;}
    h5{ font-size: 14px;}
    h6{ font-size: 12px;}
    p, 
    a{ font-size: 16px;}
    a{ color: ${(props) => props.theme.fontColor}; }
    a:hover{ color: ${(props) => props.theme.accentColor}; }
    span{ color: ${(props) => props.theme.accentColor}; }

    /* Tabs styling */
    .react-tabs {
        border-color: ${(props) => props.theme.accentColor} !important;
        border-top: 1px solid;
        border-right: 1px solid; 
        border-top-right-radius: 6px;
        border-bottom: none;
    }
    @media screen and (max-width: 768px){
        .react-tabs {
            border-top: 1px solid;
            border-right: none;
            border-top-right-radius: 0;
        }
        .react-tabs__tab-list {
            margin: 0 auto;
            text-align: center;
            padding: 0;
        }
    }
    .react-tabs__tab-list {
        border-top-right-radius: 6px;
        margin: 0 0 10px;
        padding: 1.25%;  
        border: none;
    }
    .react-tabs__tab {
        border: none;
    }
    .react-tabs__tab--selected {
        border-bottom: 1px solid ${(props) => props.theme.accentColor} !important;
        border-radius: 6px 6px 0 0;
        background: none;
        color: ${(props) => props.theme.fontColor};
    }
    .react-tabs__tab:focus {
        box-shadow: none;
        border-radius: 6px 6px 0 0;
        border: 1px solid ${(props) => props.theme.accentColor};        
    }
    .react-tabs__tab:focus:after {
        background: none;
    }
    
    /* Y-Scrollbar */
    body::-webkit-scrollbar {
        width: 6px;
    }
    body::-webkit-scrollbar-track {
        border-radius: 4px;
        background: ${(props) => props.theme.body};
    }
    body::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollThumbColor};
        border-radius: 6px;
    }
    body::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.scrollThumbHover};
    }

    /* Mobile */
    @media screen and (max-width: 375px) and (min-width: 280px){
        h1{ font-size: 30px;}
        h2{ 
            font-size: 22px;
        }
        h3{ font-size: 16px;}
        p, 
        a{ font-size: 14px;}
    }
    /* Tablet */
    @media screen and (max-width: 768px) and (min-width: 376px){
        h1{ font-size: 36px;}
        h2{ font-size: 30px; }
        h3{ font-size: 18px;}
        p, 
        a{ font-size: 15px;}
    }
`;