export const Prj1 = {
    url: "https://malinsoderlund.com",
    img: require('../../images/reactportfolio.png').default,
    alt: 'Screenshot of ReactJS Portfolio Project',
    title: 'React Portfolio',
    desc: 'The website you are currently on was created with ReactJS and Styled Components for styling CSS and Lottie files together with After Effects for animations. This website is hosted on Github Pages with a custom domain.',
    techItem1: 'ReactJS',
    techItem2: 'JavaScript',
    techItem4: 'After Effects',
    github: 'https://github.com/maso1905/portfolio-v1'
};

export const Prj2 = {
    url: "https://molliepoppin.itch.io/operation-full-moon",
    img: require('../../images/ofmScr.png').default,
    alt: 'Screenshot of Operation Full Moon Project',
    title: 'Operation Full Moon',
    desc: 'A first person 3D game demo I created with Unity. The projects was a part of a weekly challenge, provided by the indie game developer David Whele and his course Game Dev Unlocked.',
    techItem1: 'Unity',
    techItem2: 'C#',
    techItem3: 'PlayMaker',
    github: ''
};

export const Prj3 = {
    url: "https://www.diva-portal.org/smash/get/diva2:1564118/FULLTEXT01.pdf",
    img: require('../../images/thesisproject.png').default,
    alt: 'Screenshot of Thesis Project',
    title: 'Webserver From ASP.NET 4.8 to Blazor Server',
    desc: 'For my thesis I collaborated with the gaming entertainment group FunRock to work on their admin server site for the mobile game MMA Manager.',
    techItem1: 'Blazor',
    techItem2: 'C#',
    techItem3: 'Bootstrap',
    techItem4: 'Docker',
    github: ''
};

export const Prj4 = {
    url: "https://portfoliowordpress.malinsoderlund.com/blog/",
    img: require('../../images/wpportfolio.png').default,
    alt: 'Screenshot of WordPress Portfolio Project',
    title: 'WordPress Portfolio',
    desc: 'WordPress theme I made with the purpose of having a dedicated school-work portfolio. I wanted to include it to reference my technical improvements from year 2020. Here you can find some more of my school projects.',
    techItem1: 'WordPress',
    techItem2: 'PHP',
    github: 'https://github.com/maso1905/wordpressPortfolio'
};

export const Prj5 = {
    url: 'https://www.youtube.com/watch?v=hUUaGYvzXes',
    img: require('../../images/thegamecorner.png').default,
    alt: 'Screenshot of The Game Corner Project',
    title: 'The Game Corner',
    desc: 'The project is a ASP.NET Core Entity Framework web application with CRUD functionality. It was initially published with free trial on Azure. You can create a login account and upload/edit/delete images.',
    techItem1: 'C#',
    techItem2: 'ASP.NET Core',
    techItem3: 'Azure',
    github: 'https://github.com/maso1905/theGameCorner'
};

export const Prj6 = {
    url: 'http://studenter.miun.se/~maso1905/dt084g/projektarbete/projekt_sr/',
    img: require('../../images/srradio.png').default,
    alt: 'Screenshot of Swedish Radio Open API Project',
    title: 'Swedish Radio Open API',
    desc: 'A tableu web application made with the open API of the Swedish Radio. I used AJAX in order to format the desired API’s URL to JSON.',
    techItem1: 'JavaScript',
    techItem2: 'AJAX',
    techItem3: 'API',
    techItem4: 'JSON',
    github: 'https://github.com/maso1905/projectSR'
};

export const Prj7 = {
    url: "https://molliepoppin.itch.io/operation-full-moon",
    img: require('../../images/ofmScr.png').default,
    alt: 'Screenshot of 3D Game Project',
    title: 'Operation Full Moon - Full Game',
    desc: 'After the release on Itch.io and the great response, I have the ambition to continue develop it into a full game. But this time I will convert from Playmaker into C#',
    techItem1: 'Unity',
    techItem2: 'C#',
    github: ''
};

export const Prj8 = {
    url: '',
    img: require('../../images/2dgame.png').default,
    alt: 'Screenshot of 2D Game Project',
    title: '2D Game',
    desc: 'I´m in the process of creating a 2D game with the purpose of deepen my knowledge in Adobe Photoshop and animations.',
    techItem1: 'Unity',
    techItem2: 'C#',
    techItem3: 'Adobe Photoshop',
    github: ''
};

export const Prj9 = {
    url: 'https://www.figma.com/proto/5CejsyJyXzQ5eW7VmOJi2F/Untitled?node-id=63%3A1451&scaling=scale-down&page-id=0%3A1&starting-point-node-id=63%3A1451',
    img: require('../../images/radyarn.png').default,
    alt: 'Screenshot of Website Mock-up',
    title: 'RAD! Yarn',
    desc: 'I´m currently developing a web design for a fictional company. The mock-up is updating regularly and the final product will result as a React app.',
    techItem1: 'Figma',
    techItem2: 'React',
    github: ''
};